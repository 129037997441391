exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-model-innovation-js": () => import("./../../../src/pages/business-model-innovation.js" /* webpackChunkName: "component---src-pages-business-model-innovation-js" */),
  "component---src-pages-design-research-js": () => import("./../../../src/pages/design-research.js" /* webpackChunkName: "component---src-pages-design-research-js" */),
  "component---src-pages-design-thinking-facilitation-js": () => import("./../../../src/pages/design-thinking-facilitation.js" /* webpackChunkName: "component---src-pages-design-thinking-facilitation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

